.app {
  min-height: 100vh;
  background: linear-gradient(135deg, #ff7676 0%, #f54ea2 100%);
  position: relative;
  overflow: hidden;
  padding: 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.search-bar input {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  border: none;
  width: 300px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin-left: 2rem;
}

.gift-finder {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.3s ease;
  position: relative;
}

.gift-finder:hover {
  transform: translateY(-5px);
}

.gift-finder h2 {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.gift-finder input {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.gift-finder input:focus {
  border-color: #f54ea2;
  outline: none;
  box-shadow: 0 0 0 3px rgba(245, 78, 162, 0.2);
}

.gift-finder input[type="text"] {
  width: 95.5%;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.gift-finder input[type="text"]:focus {
  border-color: #f54ea2;
  outline: none;
  box-shadow: 0 0 0 3px rgba(245, 78, 162, 0.2);
}

.gift-finder input[type="number"] {
  width: 93.5%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.gift-finder input[type="number"]:focus {
  border-color: #f54ea2;
  outline: none;
  box-shadow: 0 0 0 3px rgba(245, 78, 162, 0.2);
}

button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(45deg, #f54ea2, #ff7676);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(245, 78, 162, 0.3);
}

.recommendations-result {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.recommendations-result pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.recommendations-text a {
  display: inline-block;
  margin: 10px 0;
  padding: 8px 16px;
  background-color: #FF9900;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.recommendations-text a:hover {
  background-color: #FF8C00;
}

.recommendations-text a.amazon-button {
  display: inline-block;
  margin: 10px 0;
  padding: 8px 16px;
  background-color: #FF9900;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.recommendations-text a.amazon-button:hover {
  background-color: #FF8C00;
  text-decoration: none;
}

.home-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  text-align: center;
  position: relative;
  z-index: 1;
}

.welcome-content {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease;
}

.welcome-content:hover {
  transform: translateY(-5px);
}

.welcome-content h2 {
  color: #c41e3a;
  margin-bottom: 1.5rem;
}

.welcome-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.start-button {
  font-size: 1.2rem;
  padding: 1rem 2rem;
  background-color: #c41e3a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-button:hover {
  background-color: #a01830;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  margin: 2rem auto;
  max-width: 600px;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #f54ea2;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

.loading-text {
  color: #f54ea2;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.loading-subtext {
  color: #666;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0.5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.snowflake {
  position: fixed;
  color: white;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  user-select: none;
  z-index: 1;
  animation: fall linear infinite;
}

@keyframes fall {
  0% {
    transform: translate(0, -10vh);
    opacity: 1;
  }
  100% {
    transform: translate(20px, 100vh);
    opacity: 0.3;
  }
}

.form-select {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 1rem;
  background-color: white;
  transition: all 0.3s ease;
  cursor: pointer;
}

.form-select:focus {
  border-color: #f54ea2;
  outline: none;
  box-shadow: 0 0 0 3px rgba(245, 78, 162, 0.2);
}

.form-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.recommendations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.refresh-button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  background: linear-gradient(45deg, #f54ea2, #ff7676);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  min-width: 120px;
}

.refresh-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(245, 78, 162, 0.3);
}

.refresh-button:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.tag-input-container {
  width: 94.5%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: white;
  transition: all 0.3s ease;
}

.tag-input-container:focus-within {
  border-color: #f54ea2;
  box-shadow: 0 0 0 3px rgba(245, 78, 162, 0.2);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.tag {
  background: #f54ea2;
  color: white;
  padding: 0.5rem 0.8rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
}

.tag-remove {
  background: none;
  border: none;
  color: white;
  padding: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  transition: transform 0.2s;
}

.tag-remove:hover {
  transform: scale(1.2);
  box-shadow: none;
}

.tag-input {
  width: 95%;
  padding: 0.5rem;
  border: none;
  outline: none;
  font-size: 1rem;
  background: transparent;
}

.tag-input:focus {
  outline: none;
  box-shadow: none;
} 